import React from 'react';

function David() {
    return (
        <>
            <img className="object-contain w-full h-full" draggable="false" src="david.png" alt="David Portrait"/>
        </>
    );
}

export default David;
